/* globals window */
/* globals document */
/* globals XMLHttpRequest */

define([
   './lib/bowser-1.9.1.js',
   './lib/modernizr-3.3.1-20160201.js',
   './styles.css'
],
   function(bowser, Modernizr)
   {
      'use strict';
      var onError = null;
      function swBootstrap(error, ie10supported, success, unsupportedBrowser, unsupportedCookies)
      {
         onError = error;
         window.onload = function _onLoad()
         {
            /***************************************************************
            var s = 'version: ' + bowser.version + '\n';
            s += bowser.safari  ? 'safari \n' : '';
            s += bowser.msie    ? 'msie   \n' : '';
            s += bowser.chrome  ? 'chrome \n' : '';
            s += bowser.firefox ? 'firefox\n' : '';
            s += bowser.msedge  ? 'msedge \n' : '';
            s += bowser.webkit  ? 'webkit:  ' + bowser.webkit  + '\n' : '';
            s += bowser.trident ? 'trident: ' + bowser.trident + '\n' : '';
            s += bowser.mozilla ? 'mozilla: ' + bowser.mozilla + '\n' : '';
            s += Modernizr.flexbox       ? 'flexbox      \n' : '';
            s += Modernizr.fontface      ? 'fontface     \n' : '';
            s += Modernizr.queryselector ? 'queryselector\n' : '';
            s += Modernizr.cookies       ? 'cookies      \n' : '';
            alert(s);
            ***************************************************************/

            if ( _isActualVersionOfBrowser(ie10supported) &&
                  // Modernizr.flexbox  &&  // turned off to support ie10
                  Modernizr.fontface &&
                  Modernizr.queryselector )
            {
               if ( Modernizr.cookies )
               {
                  success({
                     addStyle: addStyle,
                     addScript: addScript,
                     getUrlParams: getUrlParams,
                     setUserSession: setUserSession
                  });
               }
               else
               {
                  unsupportedCookies();
               }
            }
            else
            {
               unsupportedBrowser();
            }
         };

         function _isActualVersionOfBrowser(ie10supported)
         {
            var result = (bowser.safari  && bowser.version >=  9) ||
                         (bowser.msie    && bowser.version >= (ie10supported ? 10 : 11)) ||
                         (bowser.chrome  && bowser.version >= 54) ||
                         (bowser.firefox && bowser.version >= 50) ||
                         (bowser.msedge  && bowser.version >= 14) ||
                         (bowser.webkit  >= 537.36) ||
                         // (bowser.trident >= 6.0)    || // see https://msdn.microsoft.com/en-us/library/ms537503(v=vs.85).aspx#TriToken
                         (bowser.mozilla >= 5.0);
            return result;
         }
      }

      function addScript(src, onErrorStr)
      {
         var s = document.createElement('script');
         s.setAttribute('src', src);
         s.setAttribute('onerror', onErrorStr);
         document.body.appendChild(s);
      }

      function addStyle(src, onErrorStr)
      {
         var l = document.createElement('link');
         l.setAttribute('rel',   'stylesheet');
         l.setAttribute('type',  'text/css');
         l.setAttribute('media', 'all');
         l.setAttribute('href',   src);
         l.setAttribute('onerror', onErrorStr);
         document.head.appendChild(l);
      }

      // https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript#answer-2880929
      function getUrlParams()
      {
         var urlParams = {};
         var match;
         var pl      = /\+/g;  // Regex for replacing addition symbol with a space
         var search  = /([^&=]+)=?([^&]*)/g;
         var query   = window.location.search.substring(1);
         var decode  = function(s)
         {
            return decodeURIComponent(s.replace(pl, ' '));
         };
         while (match = search.exec(query)) // eslint-disable-line no-cond-assign
         {
            urlParams[decode(match[1])] = decode(match[2]);
         }
         return urlParams;
      }

      function processParams()
      {
         var urlParams = getUrlParams();
         var otpRegExp = /[\&|\?](login)?otp\=\w*/i;
         var codeRegExp = /code=\w*/i;
         var currentLocationRegExp = /currentLocation=\w*/i;
         var query = window.location.search;

         if (urlParams.hasOwnProperty('otp') || urlParams.hasOwnProperty('loginOtp'))
         {
            query = query.replace(otpRegExp, '');
            if (query[0] === '&')
            {
               query = '?' + query.substring(1);
            }
         }
         if (urlParams.hasOwnProperty('code'))
         {
            query = query.replace(codeRegExp, '');
            if (query[0] === '&')
            {
               query = '?' + query.substring(1);
            }
            if (query[query.length-1] === '&')
            {
               query = query.slice(0, -1);
            }
         }
         if (urlParams.hasOwnProperty('currentLocation'))
         {
            query = query.replace(currentLocationRegExp, '');
            if (query[0] === '&')
            {
               query = '?' + query.substring(1);
            }
            if (query[query.length-1] === '&')
            {
               query = query.slice(0, -1);
            }
         }
         if (query.length === 0)
         {
            query = window.location.pathname;
         }
         query += window.location.hash;
         window.history.replaceState({}, '', query);
      }

      function fetch(path, params, headers, callback) {
         var xhr = new XMLHttpRequest();
         var query = window.location.search;
         var url = path;

         for (var key in params)
         {
            if (params.hasOwnProperty(key))
            {
               url += ';' + key + '=' + params[key];
            }
         }
         url += query;

         xhr.onreadystatechange = function()
         {
            if (xhr.readyState === XMLHttpRequest.DONE)
            {
               if (!xhr.status || xhr.status >= 400)
               {
                  return onError();
               }
               callback(JSON.parse(xhr.response));
            }
         };
         xhr.open('GET', url, true);
         xhr.setRequestHeader('Accept', 'application/json, text/plain');
         for (var key in headers)
         {
            if (headers.hasOwnProperty(key))
            {
               xhr.setRequestHeader(key, headers[key]);
            }
         }
         xhr.send();
      }

      function InitialUserSessionObs(session)
      {
         var _userSession = _assign({}, session);
         var _isConfigLoaded = false;
         var _isParametersLoaded = false;
         var _observers = [];
         processParams();

         function notify()
         {
            if (_isConfigLoaded && _isParametersLoaded)
            {
               _observers.forEach(function(observer)
               {
                  observer(_userSession);
               });
            }
         }

         this.subscribe = function subscribe(callback)
         {
            _observers.push(callback);
            notify();
         };

         this.reset = function reset()
         {
            _userSession.userSession = null;
         };

         fetch('rest/config', {}, {}, function(config)
         {
            if (config.messages && config.messages.length > 0)
            {
               return onError();
            }
            _userSession = _assign(_userSession, config);
            _isConfigLoaded = true;
            notify();
         });

         var _us = _userSession.userSession;
         if (_us)
         {
            var urlParams = !_us.sessionToken ? { jsessionid: _us.id } : { jsessionid: _us.id, sessionToken: _us.sessionToken };
            var headers = !_us.accessToken ? {} : { authorization: 'Bearer ' + _us.accessToken };
            fetch('rest/usersession/parameters', urlParams, headers,
            function(parameters)
            {
               if (parameters.messages && parameters.messages.length > 0)
               {
                  return onError();
               }
               _userSession.userSessionParameters = parameters;
               _isParametersLoaded = true;
               notify();
            });
         }
         else
         {
            _isParametersLoaded = true;
         }
      };

      function setUserSession(userSession)
      {
         // namespace sw is defined in index.html
         window.sw.initialUserSession$ = new InitialUserSessionObs(userSession);
      }

      function _assign(target) {
         if (target === undefined || target === null)
         {
            throw new TypeError('Cannot convert undefined or null to object');
         }
         var to = Object(target);
         for (var index = 1; index < arguments.length; index++)
         {
            var nextSource = arguments[index];
            if (nextSource !== undefined || nextSource !== null)
            {
               for (var nextKey in nextSource)
               {
                  // Avoid bugs when hasOwnProperty is shadowed
                  if (Object.prototype.hasOwnProperty.call(nextSource, nextKey))
                  {
                     to[nextKey] = nextSource[nextKey];
                  }
               }
            }
         }
         return to;
      }
      // namespace sw is defined in index.html
      window.sw.bootstrap = swBootstrap;
});
